import React from 'react'
import clsx from 'clsx'
import classnames from '../assets/styles/Order.module.scss'
import classtextnames from '../../../ui-kit/Text/text.module.scss'
import { ButtonInfo } from '../api/dto/ordersTypes'
import { Row } from '../../../ui-kit/Tabular/Row'
import { Col } from '../../../ui-kit/Tabular/Col'
import { OrderStatus } from './OrderStatus'
import { PaymentButton } from './PaymentButton'

export type OrderHeaderProps = {
  createdOn: string | null
  orderNumber: string
  orderStatus: string
  buttonInfo: ButtonInfo
}

export function OrderHeader({ createdOn, orderNumber, orderStatus, buttonInfo }: OrderHeaderProps) {
  return (
    <Row className="align-items-center">
      <Col className="d-flex flex-column w-50">
        <OrderStatus status={orderStatus} />
        <div className={classnames.orderHeaderDateColMobile}>
          <h1 className="mb-0 text-break">{orderNumber}</h1>
          <span className={classtextnames.textColorGrey}>{createdOn}</span>
        </div>
        <span className={clsx('mb-4', 'd-none', 'd-xl-block', classtextnames.textColorGrey)}>
          {createdOn}
        </span>
      </Col>
      <Col xs="2" className={clsx('text-right', 'mb-1', 'd-none', 'd-xl-block')}>
        <PaymentButton link={buttonInfo.link} visible={buttonInfo.view} />
      </Col>
    </Row>
  )
}
