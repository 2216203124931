import React from 'react'
import deliveryCourier from '../../assets/images/courier.svg'
import deliveryRecipient from '../../assets/images/recipient.svg'
import deliveryDetails from '../../assets/images/details.svg'
import deliveryBonuses from '../../assets/images/bonuses.svg'
import clsx from 'clsx'
import classnames from '../../assets/styles/Order.module.scss'
import classtextnames from '../../../../ui-kit/Text/text.module.scss'
import { BonusType, ContactsType, DeliveryType, OrderDetailsType } from '../../api/dto/ordersTypes'
import { DeliveryRow } from './DeliveryRow'
import { DetailsRow } from './DetailsRow'
import { Row } from '../../../../ui-kit/Tabular/Row'
import { Col } from '../../../../ui-kit/Tabular/Col'

export type DeliveryProps = {
  delivery: DeliveryType
  detail: OrderDetailsType
  contact: ContactsType
  bonuses?: BonusType
}

export function Delivery({ delivery, detail, contact, bonuses }: DeliveryProps) {
  return (
    <Row className="pl-3 pt-4">
      <Col className="pl-0">
        {delivery.address && (
          <Col>
            <DeliveryRow
              srcImage={deliveryCourier}
              description={delivery.type ? delivery.type : 'Доставка'}
            />
            <Row className={clsx('w-85', classnames.deliveryDescriptionsPaddingLeft)}>
              {delivery.address}
            </Row>
          </Col>
        )}

        {contact.isContactDifferent && (
          <Col className="mt-4">
            <DeliveryRow srcImage={deliveryRecipient} description="Другой получатель" />
            <Row className={clsx('w-85', classnames.deliveryDescriptionsPaddingLeft)}>
              {contact.name}
            </Row>
            <Row className={clsx('w-85', classnames.deliveryDescriptionsPaddingLeft)}>
              {contact.email}
            </Row>
            <Row className={clsx('w-85', classnames.deliveryDescriptionsPaddingLeft)}>
              {contact.phone}
            </Row>
          </Col>
        )}

        {bonuses?.bonusCharge && (
          <Col className="mt-4">
            <DeliveryRow srcImage={deliveryBonuses} description="Бонусы" />
            <Row className={clsx('w-85', classnames.deliveryDescriptionsPaddingLeft)}>
              За заказ будет начислено {String(bonuses.bonusCharge)} бонусных рублей.
            </Row>
          </Col>
        )}
      </Col>

      <Col className={classnames.orderDetails}>
        <DeliveryRow srcImage={deliveryDetails} description="Детали заказа" className="mb-2" />
        <DetailsRow details={detail.orderAmount} colText="Сумма заказа" />

        {detail.discountSum !== undefined && detail.discountSum !== 0 && (
          <DetailsRow
            details={-detail.discountSum}
            colText="Оплачено бонусами"
            textFormat="text-danger"
          />
        )}

        {detail.renderSum !== undefined && detail.renderSum !== 0 && (
          <DetailsRow details={detail.renderSum} colText="Оплачено" />
        )}

        {detail.amount !== 0 && (
          <DetailsRow
            details={detail.amount}
            colText={'Итого к оплате'}
            textFormat={classtextnames.totalBold}
          />
        )}
      </Col>
    </Row>
  )
}
