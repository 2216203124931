import React from 'react'
import { ProductItem } from './ProductItem'
import { OrderProductType } from '../api/dto/ordersTypes'
import { Rollup } from '../../../components/Layout/Rollup/Rollup'

export type ProductsListProps = {
  items: OrderProductType[]
  className?: string
}

export function ProductsList({ items }: ProductsListProps) {
  return (
    <Rollup>
      {items?.map((item: OrderProductType, key) => (
        <ProductItem {...item} key={key} />
      ))}
    </Rollup>
  )
}
