import React from 'react'
import { Order } from './Order'
import { OrderType } from '../api/dto/ordersTypes'

export type OrdersListProps = {
  orders: OrderType[]
}

export function OrdersList({ orders }: OrdersListProps) {
  return (
    <>
      {orders.map((order, index) => {
        const key = `${index}-${order.number}`
        return <Order order={order} key={key} />
      })}
    </>
  )
}
