import React from 'react'
import { OrderProductType } from '../api/dto/ordersTypes'
import clsx from 'clsx'
import classnames from '../assets/styles/Order.module.scss'
import { formatPriceRu } from '../../../helpers'
import { Row } from '../../../ui-kit/Tabular/Row'
import { Col } from '../../../ui-kit/Tabular/Col'

export function ProductItem({ name, quantity, price, service }: OrderProductType) {
  return (
    <div className={classnames.borderBothLine}>
      <Row productLinePadding className={clsx(classnames.orderProduct, 'm-0')}>
        <Col xs="9" className={clsx('pl-2', classnames.orderName)}>
          {name}
        </Col>
        <Col xs="1" className={clsx(classnames.orderQuantity)}>
          × {quantity} шт.
        </Col>
        <Col xs="1" className={clsx(classnames.orderQuantityMobile)}>
          × {quantity} шт.
        </Col>
        <Col xs="2" className={clsx('text-right font-weight-bold pr-0', classnames.orderPrice)}>
          {formatPriceRu(price)}
        </Col>
      </Row>
      {Array.isArray(service) &&
        service.map((item) => {
          return (
            <Row
              key={item.name}
              className={clsx('position-relative m-0', classnames.orderServiceRow)}
            >
              <Col xs="9" className={clsx(classnames.orderService, classnames.orderServiceName)}>
                <span>{item.name}</span>
              </Col>
              <Col xs="1" className={clsx(classnames.orderQuantity, classnames.orderService)}>
                × {item.quantity} шт.
              </Col>
              <Col xs="1" className={classnames.orderQuantityMobile}>
                × {item.quantity} шт.
              </Col>
              <Col
                xs="2"
                className={clsx(
                  'text-right',
                  'font-weight-bold',
                  'pr-0',
                  'mb-3',
                  classnames.orderService,
                  classnames.orderServicePrice,
                )}
              >
                {formatPriceRu(item.price)}
              </Col>
            </Row>
          )
        })}
    </div>
  )
}
