import React, { useState } from 'react'
import classnames from './Rollup.module.scss'
import clsx from 'clsx'

type RollupType = {
  children: React.ReactNode
}

export function Rollup({ children }: RollupType) {
  const [productsVisibility, setProductsVisibility] = useState(false)
  const rollupState = {
    visibility: productsVisibility ? classnames.showProducts : classnames.hideProducts,
    icon: productsVisibility ? '' : classnames.productExpand,
    text: productsVisibility ? 'Свернуть' : 'Подробнее',
  }
  const rollup = () => {
    setProductsVisibility(!productsVisibility)
  }
  return (
    <>
      <span className={clsx(classnames.productsRollup, rollupState.icon)} onClick={rollup}>
        {rollupState.text}
      </span>
      <div className={rollupState.visibility}>{children}</div>
    </>
  )
}
