import React from 'react'
import clsx from 'clsx'
import classnames from '../../assets/styles/Order.module.scss'
import { Row } from '../../../../ui-kit/Tabular/Row'

export type DeliveryRowProps = {
  srcImage: string
  description: string
  className?: string
}

export function DeliveryRow(props: DeliveryRowProps) {
  return (
    <Row className={`d-flex flex-nowrap align-items-start ${props.className}`}>
      <img
        className={clsx('mr-2', classnames.deliveryIconsImg)}
        src={props.srcImage}
        alt={props.description}
      />
      <strong>{props.description}</strong>
    </Row>
  )
}
