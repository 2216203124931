import { OrderType } from '../pages/Order/api/dto/ordersTypes'
import axios, { AxiosError } from 'axios'
import { TokenType } from './dto/Token'
import { BACKEND_URL_PREFIX } from './apiConfig'

type ClientOptions = {
  token?: string
  contact?: string
}

const getClient = ({ token, contact }: ClientOptions = {}) => {
  const client = axios.create({
    headers: {
      ...(token ? { 'User-Token': token } : undefined),
      ...(contact ? { 'User-Contact': contact } : undefined),
      common: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      patch: {
        'Content-Type': 'application/json',
      },
      post: {
        'Content-Type': 'application/json',
      },
      put: {
        'Content-Type': 'application/json',
      },
    },
    withCredentials: false,
  })

  client.interceptors.response.use(
    (response) => {
      return response.data
    },
    (error: AxiosError) => {
      throw error.response
    },
  )

  return client
}

export const getOrders = async (): Promise<OrderType[]> => {
  const clientToken = localStorage.getItem('clientToken')
  const clientContact = localStorage.getItem('clientContact')
  if (!clientToken || !clientContact) {
    throw new Error('Credentials are empty')
  }
  return getClient({ token: clientToken, contact: clientContact }).get(
    `${BACKEND_URL_PREFIX}/backend/api/orders`,
  )
}

export const getToken = async (contactBpmID: string): Promise<string> => {
  const token: TokenType = await getClient().get(`${BACKEND_URL_PREFIX}/backend/authorize/token`, {
    params: {
      contactBpmID,
    },
  })
  return token.token
}
