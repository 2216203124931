import { useEffect, useState } from 'react'
import queryString from 'query-string'
import { getToken } from '../api/apiOrders'

export function useCheckContact() {
  const [isLoading, setIsLoading] = useState(true)
  const [isContactBpmID, setIsContactBpmID] = useState(true)

  useEffect(() => {
    const {
      query: { contactBpmID },
    } = queryString.parseUrl(window.location.href, {
      arrayFormat: 'comma',
      parseNumbers: true,
      parseFragmentIdentifier: true,
    })

    const clientContact = contactBpmID
    if (!clientContact) {
      setIsContactBpmID(false)
      setIsLoading(false)
    }
    if (typeof clientContact === 'string') {
      getToken(clientContact)
        .then((token) => {
          if (token) {
            localStorage.setItem('clientContact', clientContact)
            localStorage.setItem('clientToken', token)
          }
          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
          localStorage.removeItem('clientContact')
          localStorage.removeItem('clientToken')
        })
    }

    return () => {
      localStorage.removeItem('clientContact')
      localStorage.removeItem('clientToken')
    }
  }, [])

  return { isLoading, isContactBpmID }
}
