// FIXME пока eslint не воспринимает enum
/* eslint-disable */
export enum EOrderStatuses {
  REGISTRATION = 'Оформление',
  PROCESSING = 'Обработка',
  PRODUCTION = 'Производство',
  SUPPLY = 'Поставка',
  READY_FOR_SHIPMENT = 'Готов к отгрузке',
  COMPLETED = 'Выполнен',
  CANCELED = 'Отменен',
  UNKNOWN = 'Статус не определен',
}
