import React from 'react'
import { Content } from './components/Content/Content'
import { OrderPage } from './pages/Order/OrderPage'
import './styles/app.scss'
import { useCheckContact } from './hooks/useCheckContact'
import { OrdersNotFound } from './pages/Order/components/OrdersNotFound'

const App = () => {
  const contactResult = useCheckContact()

  if (contactResult.isLoading) return null

  return (
    <div>
      <Content>{contactResult.isContactBpmID ? <OrderPage /> : <OrdersNotFound />}</Content>
    </div>
  )
}

export default App
