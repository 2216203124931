import React, { FC } from 'react'
import { Col as ColStrap } from 'reactstrap'

type Props = {
  children: React.ReactNode
  className?: string
  xs?: string
}

export const Col: FC<Props> = ({ children, ...props }) => {
  return <ColStrap {...props}>{children}</ColStrap>
}
