import React from 'react'
import classnames from './ContentBlock.module.scss'
import clsx from 'clsx'

type ContentBlockProps = {
  children: React.ReactNode
}

export function ContentBlock({ children }: ContentBlockProps) {
  return <div className={clsx('bg-white', classnames.contentBlock)}>{children}</div>
}
