import React, { useCallback } from 'react'
import { Button } from '../../../ui-kit/Buttons/Button'

type Props = {
  link: string
  visible?: boolean
  className?: string
}

export function PaymentButton({ link, visible = false, className = '' }: Props) {
  const redirect = useCallback(() => {
    window.open(link, '_blank')
  }, [link])

  if (!visible) {
    return null
  }

  return (
    <Button color="primary" className={className} onClick={redirect}>
      Оплатить
    </Button>
  )
}
