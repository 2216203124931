import React from 'react'
import { OrderHeader } from './OrderHeader'
import { ProductsList } from './ProductsList'
import { ContentBlock } from '../../../components/Layout/ContentBlock/ContentBlock'
import { Delivery } from './Delivery/Delivery'
import { OrderType } from '../api/dto/ordersTypes'
import clsx from 'clsx'
import classnames from '../assets/styles/Order.module.scss'
import { PaymentButton } from './PaymentButton'

export type OrderProps = {
  order: OrderType
}

export function Order({ order }: OrderProps) {
  return (
    <ContentBlock>
      <OrderHeader
        createdOn={order.createdOn}
        orderNumber={order.number}
        orderStatus={order.status}
        buttonInfo={order.button}
      />
      <ProductsList items={order.products} />
      <Delivery
        delivery={order.delivery}
        contact={order.contact}
        detail={order.detail}
        bonuses={order.bonuses}
      />
      <PaymentButton
        link={order.button.link}
        visible={order.button.view}
        className={clsx('d-block d-xl-none w-100', classnames.payButtonMobile)}
      />
    </ContentBlock>
  )
}
