import React, { FC } from 'react'
import { Button as ButtonStrap, ButtonProps } from 'reactstrap'
import clsx from 'clsx'
import classnames from './Button.module.scss'

type Props = {
  children: React.ReactNode
  color?: 'outline-primary' | 'primary' | 'outline-dark' | 'secondary'
  className?: string
}

export const Button: FC<Props & ButtonProps> = ({ children, className, ...props }) => {
  return (
    <ButtonStrap className={clsx(className, classnames.button)} {...props}>
      {children}
    </ButtonStrap>
  )
}
