import React from 'react'
import clsx from 'clsx'
import classnames from '../assets/styles/Order.module.scss'
import { EOrderStatuses } from '../api/dto/EOrderStatus'

export type OrderStatusProps = {
  status: string
}

export type BadgeStatus = {
  description: string
  backgroundColor: string
}

const mapStatusToColor: Record<string, BadgeStatus> = {
  [EOrderStatuses.REGISTRATION]: {
    description: EOrderStatuses.REGISTRATION,
    backgroundColor: classnames.badgeStatusRegistration,
  },
  [EOrderStatuses.PROCESSING]: {
    description: EOrderStatuses.PROCESSING,
    backgroundColor: classnames.badgeStatusProcessing,
  },
  [EOrderStatuses.PRODUCTION]: {
    description: EOrderStatuses.PRODUCTION,
    backgroundColor: classnames.badgeStatusProduction,
  },
  [EOrderStatuses.SUPPLY]: {
    description: EOrderStatuses.SUPPLY,
    backgroundColor: classnames.badgeStatusSupply,
  },
  [EOrderStatuses.READY_FOR_SHIPMENT]: {
    description: EOrderStatuses.READY_FOR_SHIPMENT,
    backgroundColor: classnames.badgeStatusReady,
  },
  [EOrderStatuses.COMPLETED]: {
    description: EOrderStatuses.COMPLETED,
    backgroundColor: classnames.badgeStatusCompleted,
  },
  [EOrderStatuses.CANCELED]: {
    description: EOrderStatuses.CANCELED,
    backgroundColor: classnames.badgeStatusCanceled,
  },
}

const UNKNOWN_BADGE: BadgeStatus = {
  description: EOrderStatuses.UNKNOWN,
  backgroundColor: classnames.badgeStatusRegistration,
}

export function OrderStatus({ status }: OrderStatusProps) {
  const { description, backgroundColor } = mapStatusToColor[status] ?? UNKNOWN_BADGE

  return (
    <span className={clsx('badge mb-2', classnames.badgeStatus, backgroundColor)}>
      {description}
    </span>
  )
}
