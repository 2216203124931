import React, { FC } from 'react'
import { Row as RowStrap } from 'reactstrap'
import classnames from './tabular.module.scss'
import clsx from 'clsx'

type Props = {
  children: React.ReactNode
  className?: string
  borderLine?: 'top' | 'bottom' | 'both'
  productLinePadding?: boolean
}

export const Row: FC<Props> = ({ children, className, borderLine, productLinePadding }) => {
  const borderLineType = {
    [classnames.borderTopLine]: borderLine === 'top',
    [classnames.borderBottomLine]: borderLine === 'bottom',
    [classnames.borderBothLine]: borderLine === 'both',
  }
  return (
    <RowStrap
      className={clsx(
        className,
        borderLineType,
        productLinePadding ? classnames.productLinePadding : '',
      )}
    >
      {children}
    </RowStrap>
  )
}
