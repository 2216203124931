import React, { useEffect, useState } from 'react'
import { getOrders } from '../../api/apiOrders'
import { OrdersList } from './components/OrdersList'
import { OrderType } from './api/dto/ordersTypes'
import { OrdersNotFound } from './components/OrdersNotFound'

export const OrderPage = () => {
  const [orders, setOrders] = useState<OrderType[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    async function fetchOrders() {
      try {
        const orders = await getOrders()
        orders && setOrders(orders)
      } catch (e) {
        setOrders([])
      }

      setIsLoading(false)
    }

    fetchOrders()
  }, [])

  if (isLoading) {
    return null
  }

  return orders.length === 0 ? <OrdersNotFound /> : <OrdersList orders={orders} />
}
