import React from 'react'
import { formatPriceRu } from '../../../../helpers'
import clsx from 'clsx'
import classnames from '../../assets/styles/Order.module.scss'
import { Row } from '../../../../ui-kit/Tabular/Row'
import { Col } from '../../../../ui-kit/Tabular/Col'

export type DetailsRowProps = {
  details: number
  colText: string
  textFormat?: string
}

export function DetailsRow(props: DetailsRowProps) {
  return (
    <Row className={clsx('mb-2', classnames.deliveryDetailsPaddingLeft)}>
      <Col className="text-nowrap">{props.colText}</Col>
      <Col className={`text-right ${props.textFormat}`}>{formatPriceRu(props.details)}</Col>
    </Row>
  )
}
